"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rectangle = exports.internalVerticalLine = exports.internalHorizontalLine = exports.internalCenteredPrint = exports.placeString = exports.stringOfChar = void 0;
function internalCenteredPrint(str, x, y, width, board, before, after) {
    let height = 0;
    str.split('\n').forEach((line) => {
        if (before) {
            line = before + line;
        }
        if (after) {
            line += after;
        }
        let start = x + Math.floor((width - line.length) / 2);
        [...line].forEach((ch) => {
            board[y][start] = ch;
            start++;
        });
        y++;
        height++;
    });
    return height;
}
exports.internalCenteredPrint = internalCenteredPrint;
function internalVerticalLine(x, y, upper, lower, left, board) {
    board[y][x] = left ? '⎜' : '⎟';
    for (let i = 1; i < upper; ++i) {
        board[y + i][x] = left ? '⎜' : '⎟';
    }
    board[y + upper][x] = left ? '⎛' : '⎞';
    for (let j = 1; j <= Math.abs(lower); ++j) {
        board[y - j][x] = left ? '⎜' : '⎟';
    }
    board[y + lower][x] = left ? '⎝' : '⎠';
}
exports.internalVerticalLine = internalVerticalLine;
;
function internalHorizontalLine(x, y, length, board) {
    for (let i = 0; i < length; ++i) {
        board[y][x + i] = '─';
    }
}
exports.internalHorizontalLine = internalHorizontalLine;
;
function rectangle(left, y, right, width, height, board) {
    internalHorizontalLine(left, y, width, board);
    internalHorizontalLine(left, y + height, width, board);
    board[y][left] = '┌';
    board[y][right] = '┐';
    for (let yy = 1; yy < height; ++yy) {
        board[yy][left] = '│';
        board[yy][right] = '│';
    }
    board[y + height][left] = '└';
    board[y + height][right] = '┘';
}
exports.rectangle = rectangle;
;
function stringOfChar(char, times) {
    return Array(times).fill(char).join('');
}
exports.stringOfChar = stringOfChar;
;
function placeString(str, background, alignment) {
    if (str.length >= background.length) {
        return str;
    }
    if (alignment === 'left') {
        return str + background.substring(str.length);
    }
    else if (alignment === 'right') {
        return background.substring(0, background.length - str.length) + str;
    }
    const gap = Math.floor(background.length / 2) - Math.floor(str.length / 2);
    return (background.substring(0, gap) + str + background.substring(gap + str.length));
}
exports.placeString = placeString;
;
