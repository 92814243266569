"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.expressionRenderer = exports.expressionParser = void 0;
const { internalCenteredPrint, internalHorizontalLine, internalVerticalLine, } = require('./lib');
class expressionParser {
    constructor() {
        this.index = 0;
        this.expression = '';
        this.expressionLen = 0;
        this.next = () => {
            const ret = this.expression[this.index];
            this.index++;
            return ret;
        };
        this.back = () => {
            this.index--;
        };
        /**
         E -> -E | F {+|- F}
         F -> G {*|/ G}
         G -> H {^ H}
         H -> (E)|V|Func|N
         V -> S
         Func -> S(E {,E})
         S -> [a-zA-Z]{[a-zA-Z0-9]}
         N -> D {. {D}}
         D -> [0-9]
         */
        this.E = () => {
            let ch = this.next();
            if (ch === '-') {
                return {
                    operator: '-',
                    right: this.E(),
                };
            }
            this.back();
            let ret = this.F();
            while (true) {
                ch = this.next();
                if (ch !== '+' && ch !== '-') {
                    this.back();
                    break;
                }
                ret = {
                    operator: ch,
                    left: ret,
                    right: this.F(),
                };
            }
            return ret;
        };
        this.F = () => {
            let ret = this.G();
            while (true) {
                let ch = this.next();
                if (ch !== '*' && ch !== '/') {
                    this.back();
                    break;
                }
                ret = {
                    operator: ch,
                    left: ret,
                    right: this.G(),
                };
            }
            return ret;
        };
        this.G = () => {
            let ret = this.H();
            while (true) {
                let ch = this.next();
                if (ch !== '^') {
                    this.back();
                    break;
                }
                ret = {
                    operator: ch,
                    left: ret,
                    right: this.H(),
                };
            }
            return ret;
        };
        this.H = () => {
            let ch = this.next();
            // (E)
            if (ch === '(') {
                const e = this.E();
                ch = this.next();
                if (ch !== ')') {
                    throw new Error('ParentheseDoNotMatch');
                }
                return {
                    operator: '(',
                    right: e,
                };
            }
            this.back();
            // Num
            const n = this.N();
            if (n !== null) {
                return {
                    operator: 'n',
                    right: n,
                };
            }
            const v = this.V();
            if (v !== null) {
                ch = this.next();
                // Variable
                if (ch !== '(') {
                    this.back();
                    return {
                        operator: 'v',
                        right: v,
                    };
                }
                // Function
                const parameters = [this.E()];
                while (true) {
                    ch = this.next();
                    if (ch === ')') {
                        return {
                            operator: 'f',
                            left: v,
                            right: parameters,
                        };
                    }
                    else if (ch === ',') {
                        parameters.push(this.E());
                    }
                    else {
                        throw new Error('InvalidFunctionParameters');
                    }
                }
            }
            throw new Error('InvalidExpression');
        };
        this.V = () => {
            let ch = this.next();
            if (!/[A-Za-z]/.test(ch)) {
                this.back();
                return null;
            }
            const strs = [ch];
            while (true) {
                ch = this.next();
                if (!/[A-Za-z0-9]/.test(ch)) {
                    this.back();
                    break;
                }
                strs.push(ch);
            }
            return strs.join('');
        };
        this.N = () => {
            let ch = this.next();
            if (!/[0-9]/.test(ch)) {
                this.back();
                return null;
            }
            const digits = [ch];
            let dot = false;
            while (true) {
                ch = this.next();
                if (ch === '.') {
                    if (dot) {
                        throw new Error('InvalidNumberFormat');
                    }
                    dot = true;
                }
                else if (!/[0-9]/.test(ch)) {
                    this.back();
                    break;
                }
                digits.push(ch);
            }
            if (dot) {
                return parseFloat(digits.join(''));
            }
            // TODO use bigint
            return parseInt(digits.join(''));
        };
        this.parse = (expression) => {
            this.expression = expression.replace(/ /g, '') + '#';
            this.expressionLen = this.expression.length;
            this.index = 0;
            const ret = this.E();
            if (this.next() !== '#') {
                throw new Error('NotAllCharactersProcessed');
            }
            return ret;
        };
    }
}
exports.expressionParser = expressionParser;
class expressionRenderer {
    constructor() {
        this.width = (exp) => {
            if (exp.width !== undefined) {
                return exp.width;
            }
            if (exp.operator === 'n' || exp.operator === 'v') {
                exp.width = exp.right.toString().length;
                return exp.width;
            }
            if (exp.operator === '-' && !exp.left) {
                exp.width = this.width(exp.right) + 2;
                return exp.width;
            }
            if (exp.operator === '(') {
                exp.width = 1 + this.width(exp.right) + 1;
                return exp.width;
            }
            if (exp.operator === 'f') {
                let length = 0;
                const expressions = exp.right;
                expressions.forEach((parameter) => {
                    length += this.width(this.firstRaw(parameter));
                });
                exp.width = exp.left.length + 1 + length + 1;
                if (exp.right.length > 1) {
                    exp.width += (exp.right.length - 1) * 2;
                }
                return exp.width;
            }
            if (exp.operator === '/') {
                exp.width =
                    1 + Math.max(this.width(this.firstRaw(exp.left)), this.width(this.firstRaw(exp.right))) + 1;
                return exp.width;
            }
            if (exp.operator === '^') {
                exp.width = this.width(exp.left) + this.width(this.firstRaw(exp.right));
                return exp.width;
            }
            exp.width =
                this.width(exp.left) + 1 + exp.operator.length + 1 + this.width(exp.right);
            return exp.width;
        };
        this.vBounds = (exp) => {
            if (exp.upper !== undefined) {
                return;
            }
            if (exp.operator === 'n' || exp.operator === 'v') {
                exp.upper = 0;
                exp.lower = 0;
                return;
            }
            if (exp.operator === '-' && !exp.left) {
                this.vBounds(exp.right);
                exp.upper = exp.right.upper;
                exp.lower = exp.right.lower;
                return;
            }
            if (exp.operator === '(') {
                this.vBounds(exp.right);
                exp.upper = exp.right.upper + 1;
                exp.lower = exp.right.lower - 1;
                return;
            }
            if (exp.operator === 'f') {
                let upper = 0;
                let lower = 0;
                exp.right.forEach((parameter) => {
                    this.vBounds(parameter);
                    const firstRawParameter = this.firstRaw(parameter);
                    upper = Math.max(upper, firstRawParameter.upper + 1);
                    lower = Math.min(lower, firstRawParameter.lower - 1);
                });
                exp.upper = upper;
                exp.lower = lower;
                return;
            }
            if (exp.operator === '/') {
                const firstRawLeft = this.firstRaw(exp.left);
                const firstRawRight = this.firstRaw(exp.right);
                this.vBounds(firstRawLeft);
                this.vBounds(firstRawRight);
                exp.upper = this.height(firstRawLeft);
                exp.lower = -this.height(firstRawRight);
                return;
            }
            if (exp.operator === '^') {
                const firstRawRight = this.firstRaw(exp.right);
                this.vBounds(exp.left);
                this.vBounds(firstRawRight);
                exp.upper = exp.left.upper + this.height(firstRawRight);
                exp.lower = exp.left.lower;
                return;
            }
            this.vBounds(exp.left);
            this.vBounds(exp.right);
            exp.upper = Math.max(exp.left.upper, exp.right.upper);
            exp.lower = Math.min(exp.left.lower, exp.right.lower);
        };
        this.height = (exp) => {
            this.vBounds(exp);
            return exp.upper - exp.lower + 1;
        };
        this.firstRaw = (exp) => {
            let ret = exp;
            while (true) {
                if (ret.operator !== '(') {
                    break;
                }
                ret = ret.right;
            }
            return ret;
        };
        this.render = (exp) => {
            this.width(exp);
            this.vBounds(exp);
            const board = Array(this.height(exp));
            for (let i = 0; i < this.height(exp); ++i) {
                board[i] = Array(exp.width).fill(' ');
            }
            this.internalRender(exp, 0, -exp.lower, exp.width, board);
            // return board.reverse().map(row => (row.join(''))).map(line => '\'' + line + '\\n\' +').join('\n');
            return board
                .reverse()
                .map((row) => row.join(''))
                .join('\n');
        };
        this.internalRender = (exp, x, y, width, board) => {
            if (exp.operator === 'n' || exp.operator === 'v') {
                internalCenteredPrint(exp.right.toString(), x, y, width, board);
                return;
            }
            if (exp.operator === '-' && !exp.left) {
                internalCenteredPrint('- ', x, y, 2, board);
                this.internalRender(exp.right, x + 2, y, exp.right.width, board);
                return;
            }
            if (exp.operator === '(') {
                internalVerticalLine(x, y, exp.upper, exp.lower, true, board);
                this.internalRender(exp.right, x + 1, y, exp.right.width, board);
                internalVerticalLine(1 + x + exp.right.width, y, exp.upper, exp.lower, false, board);
                return;
            }
            if (exp.operator === 'f') {
                internalCenteredPrint(exp.left, x, y, exp.left.length, board);
                let first = true;
                internalVerticalLine(x + exp.left.length, y, exp.upper, exp.lower, true, board);
                let start = x + exp.left.length + 1;
                exp.right.forEach((parameter) => {
                    if (!first) {
                        internalCenteredPrint(', ', start, y, 2, board);
                        start += 2;
                    }
                    first = false;
                    const firstRawParameter = this.firstRaw(parameter);
                    this.internalRender(firstRawParameter, start, y, firstRawParameter.width, board);
                    start += firstRawParameter.width;
                });
                internalVerticalLine(x + exp.width - 1, y, exp.upper, exp.lower, false, board);
                return;
            }
            if (exp.operator === '/') {
                internalHorizontalLine(x, y, exp.width, board);
                const firstRawLeft = this.firstRaw(exp.left);
                const firstRawRight = this.firstRaw(exp.right);
                const startLeft = Math.floor((exp.width - 2 - firstRawLeft.width) / 2);
                const startRight = Math.floor((exp.width - 2 - firstRawRight.width) / 2);
                this.internalRender(firstRawLeft, x + 1 + startLeft, y - firstRawLeft.lower + 1, firstRawLeft.width, board);
                this.internalRender(firstRawRight, x + 1 + startRight, y - firstRawRight.upper - 1, firstRawRight.width, board);
                return;
            }
            if (exp.operator === '^') {
                const firstRawRight = this.firstRaw(exp.right);
                this.internalRender(exp.left, x, y, exp.left.width, board);
                this.internalRender(firstRawRight, x + exp.left.width, y + exp.left.upper - firstRawRight.lower + 1, firstRawRight.width, board);
                return;
            }
            this.internalRender(exp.left, x, y, exp.left.width, board);
            internalCenteredPrint(' ' + exp.operator + ' ', x + exp.left.width, y, 2 + exp.operator.length, board);
            this.internalRender(exp.right, x + exp.left.width + 3, y, exp.right.width, board);
        };
    }
}
exports.expressionRenderer = expressionRenderer;
// const parser = new expressionParser();
// const parsed = parser.parse('-1+(2+3)/4^5-func(6,(7/8))+func1(a*3,(10.5))-(5+6)-func2(3)');
// const renderer = new expressionRenderer();
// console.log(renderer.render(parsed));
// console.log(JSON.stringify(parsed, null, 2));
// const parser = new expressionParser();
// const parsed = parser.parse('(a');
// const renderer = new expressionRenderer();
// console.log(renderer.render(parsed));
