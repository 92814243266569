import React, { Component } from 'react';
import ASCIIPre from './ascii-pre';
import { fromUrl, addToUrl } from './url-storage';
import Clipboard from 'react-clipboard.js';
import { createBrowserHistory } from 'history';
import u from 'url';

class ToolContainer extends Component {
  /**
   * ctor
   * @param {multiline, inputPrompt, help, onSubmit, sampleInput} props
   */
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      input: '',
      rendered: '',
      copiedToClipboard: false,
    };
  }
  restoreDataFromUrlStorage() {
    const dataFromUrlStorage = fromUrl(window.location.href);
    if (dataFromUrlStorage) {
      this.renderResult(dataFromUrlStorage);
    }
  }
  componentDidMount() {
    this.history = createBrowserHistory();
    this.restoreDataFromUrlStorage();
    this.unlisten = this.history.listen(() => {
      this.restoreDataFromUrlStorage();
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }
  componentDidUpdate(_, prevState) {
    if (
      this.state.input &&
      this.state.input !== prevState.input &&
      !this.state.error
    ) {
      const newUrl = addToUrl(this.state.input, window.location.href, this.props.t);
      const parsedNewUrl = u.parse(newUrl);
      this.history.push(parsedNewUrl.path);
    }
  }
  renderResult(input) {
    try {
      this.setState({
        input,
        error: '',
        copiedToClipboard: false,
      });
      this.setState({ rendered: this.props.onSubmit(input) });
    } catch (ex) {
      console.error(ex);
      this.setState({ error: this.props.errorMessage });
    }
  }
  render() {
    const { multiline, inputPrompt, help, sampleInput } = this.props;
    const onChange = (e) => {
      this.renderResult(e.target.value);
    };
    return (
      <div>
        <p></p>
        <ASCIIPre value={this.state.rendered} />
        <p></p>
        <div>
          {multiline ? (
            <textarea
              placeholder={inputPrompt}
              style={{
                width: '80%',
                height: '300px',
                fontFamily: 'Menlo',
                fontSize: '14px',
              }}
              value={this.state.input}
              onChange={onChange}
            ></textarea>
          ) : (
            <input
              type="text"
              placeholder={inputPrompt}
              style={{
                width: '80%',
                fontFamily: 'Menlo',
                fontSize: '14px',
              }}
              value={this.state.input}
              onChange={onChange}
            />
          )}
        </div>
        {this.state.input && !this.state.error && (
          <div>
            <Clipboard
              data-clipboard-text={window.location.href}
              onSuccess={() => {
                this.setState({ copiedToClipboard: true });
              }}
            >
              Copy the shareable URL to clipboard
            </Clipboard>
            {this.state.copiedToClipboard && (
              <span style={{ color: 'green' }}> ✓</span>
            )}
          </div>
        )}
        <div>
          <span style={{ color: 'red' }}>{this.state.error}</span>
        </div>
        <div
          style={{
            textAlign: 'left',
            width: '80%',
            margin: 'auto',
          }}
        >
          {help.map((line, index) => (
            <div key={`helpline_${index}`}>{line}</div>
          ))}
          <div>
            <button
              onClick={() => {
                this.renderResult(sampleInput);
              }}
            >
              Click here for example
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ToolContainer;
