import React, { Component } from 'react';
import { render } from 'ascii-seq';
import ToolContainer from '../../common/tool-container';

class SeqRenderer extends Component {
  render() {
    return (
      <ToolContainer
        key={'SeqRenderer'}
        t={'seq'}
        multiline={true}
        inputPrompt="Please input sequences here"
        help={[
          'One sequence per line, blank lines display as blank lines in the sequence diagram',
          'Sequence format: object "-- or .."  message -> object {`{"-- or .." message -> object}`}',
        ]}
        sampleInput={[
          '## C = Client ##',
          '## AS = Auth\\nServer ##',
          '## US = User\\nStore ##',
          '## NS = News\\nServer ##',
          '## S = News Store ##',
          '-- open app -> Client',
          '-- Log in --',
          'C -- logIn -> AS -- verify -> US .. user info -> AS .. logged in -> C',
          '',
          'C -- save user info locally -> C',
          '-- Get news --',
          'C -- getNews -> NS -- retrieve -> S .. news\\n(newer news first) -> NS .. news\\n(newer news first) -> C -- display -> C',
        ].join('\n')}
        errorMessage={
          'Incomplete sequence, format: multilines of "object -- message -> object -- message ... -> object"'
        }
        onSubmit={(input) => render(
          input.split('\n').map((line) => line.replace(/\\n/g, '\n'))
        )}
      />
    );
  }
}

export default SeqRenderer;
