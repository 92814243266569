"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createLayoutObject = exports.layoutObjectLeftMargin = exports.layoutObjectWidth = exports.findByName = exports.stringWidth = exports.stringHeight = exports.messageLength = void 0;
function messageLength(message) {
    // <sp>-<sp>message<sp>-><sp>
    // And there might be new lines
    return stringWidth(message) + 7;
}
exports.messageLength = messageLength;
function stringHeight(message) {
    if (!message) {
        return 1;
    }
    return message.split('\n').length;
}
exports.stringHeight = stringHeight;
function stringWidth(message) {
    if (!message) {
        return 0;
    }
    const lines = message.split('\n');
    return Math.max(...lines.map((line) => line.length));
}
exports.stringWidth = stringWidth;
function findByName(list, name) {
    return list.findIndex((item) => item.name === name);
}
exports.findByName = findByName;
function layoutObjectWidth(name, hSpacing, hPadding) {
    return stringWidth(name) + 2 * hSpacing + 2 * hPadding;
}
exports.layoutObjectWidth = layoutObjectWidth;
function layoutObjectLeftMargin(name, hSpacing, hPadding) {
    return Math.floor((stringWidth(name) + 2 * hSpacing + 2 * hPadding) / 2);
}
exports.layoutObjectLeftMargin = layoutObjectLeftMargin;
function _determineXForAdditionalLayoutObject(lastObject, additionalLayoutObject) {
    return (lastObject.x +
        lastObject.width +
        Math.max(0, lastObject.rightExtra - additionalLayoutObject.leftMargin + 1));
}
function createLayoutObject(name, hSpacing, hPadding, lastObject) {
    const layoutObject = {
        name: name || '',
        x: 0,
        width: layoutObjectWidth(name, hSpacing, hPadding),
        leftMargin: layoutObjectLeftMargin(name, hSpacing, hPadding),
        rightExtra: 0,
    };
    if (lastObject) {
        layoutObject.x = _determineXForAdditionalLayoutObject(lastObject, layoutObject);
    }
    return layoutObject;
}
exports.createLayoutObject = createLayoutObject;
