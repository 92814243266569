import LZString from 'lz-string';
import u from 'url';

function fromUrl(existingUrl) {
    const parsedUrl = u.parse(existingUrl, true);
    const currentToolInUrl = parsedUrl.query.t || 'seq';
    if (parsedUrl.query.q) {
        const storedInput = JSON.parse(LZString.decompressFromEncodedURIComponent(parsedUrl.query.q));
        const expectedTool = storedInput[0];
        const input = storedInput[1];
        if (currentToolInUrl === expectedTool) {
            return input;
        }
    }
    return null;
}

function addToUrl(input, existingUrl, currentTool) {
    const parsedUrl = u.parse(existingUrl, true);
    parsedUrl.query.q = LZString.compressToEncodedURIComponent(JSON.stringify([currentTool, input]));
    parsedUrl.search = null;
    return u.format(parsedUrl);
}

export { fromUrl, addToUrl };
