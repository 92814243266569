import React, { Component } from 'react';
import ExpressionRenderer from './tools/math/exp-renderer';
import SeqRenderer from './tools/eng/seq-renderer';
import ScheduleRenderer from './tools/schedule/schedule-renderer';
import './App.scss';
import packageJson from '../package.json';
import u from 'url';
import { createBrowserHistory } from 'history';
import Stick from './tools/stick/stick';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { currentTool: this.getCurrentTool() };
    this.tools = {
      seq: <SeqRenderer key='seqRenderer' />,
      exp: <ExpressionRenderer key='expressionRenderer' />,
      schedule: <ScheduleRenderer key='scheduleRenderer' />,
      stick: <Stick key='stick' />,
    };
  }
  getCurrentTool() {
    const parsedUrl = u.parse(window.location.href, true);
    return parsedUrl.query.t || 'seq';
  }
  componentDidMount() {
    this.history = createBrowserHistory();
    this.unlisten = this.history.listen(() => {
      this.setState({ currentTool: this.getCurrentTool() });
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }
  renderButtons() {
    const buttons = [
      { key: 'seq', name: 'Sequence Diagram' },
      { key: 'exp', name: 'Expression' },
      { key: 'schedule', name: 'Schedule' },
      { key: 'stick', name: 'Stick' },
    ].map(({ key, name }) => (
      <button
        key={key}
        className={`btn BtnGroup-item ${this.state.currentTool === key ? 'btn-primary' : ''}`}
        type="button"
        onClick={() => {
          if (window.localStorage) {
            window.localStorage.setItem('engtools-last-selected-tool', key);
          }
          const parsedUrl = u.parse(window.location.href, true);
          parsedUrl.query.t = key;
          parsedUrl.search = null;
          const newUrl = u.format(parsedUrl);
          const parsedNewUrl = u.parse(newUrl);
          this.history.push(parsedNewUrl.path);
          this.setState({ currentTool: key });
        }}
      >
        {name}
      </button>
    ));
    return (
      <div className="BtnGroup mr-2" key='buttons'>
        {buttons}
      </div>
    );
  }
  render() {
    return (
      <div className="App">
        {this.renderButtons()}
        {this.tools[this.state.currentTool]}
        <span key='version'>{packageJson.version}</span>
      </div>
    );
  }
}

export default App;
