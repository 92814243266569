import React, { Component } from 'react';
import { ScheduleRenderer as Renderer } from 'ascii-eng';
import ToolContainer from '../../common/tool-container';

class ScheduleRenderer extends Component {
  render() {
    return (
      <ToolContainer
        key={'ScheduleRenderer'}
        t={'schedule'}
        multiline={true}
        inputPrompt="Please input schedules here"
        help={[
          'One schedule per line',
          'Schedule format: YYYY-MM-dd YYYY-MM-dd name']}
        sampleInput={[
          '2010-12-01 2010-12-05 Planning',
          '2011-01-06 2011-02-06 Milestone 1',
          '2011-02-07 2011-03-07 Milestone 2',
          '2011-03-08 2011-03-31 Beta',
          '2011-04-01 2011-04-02 Production',
          '2011-04-03 2011-04-03 Complete',
        ].join('\n')}
        errorMessage={'Incomplete schedule, format: multilines of "YYYY-MM-dd YYYY-MM-dd name"'}
        onSubmit={input => (new Renderer()).render(input.split('\n'), 'calendar')}
      />
    );
  }
}

export default ScheduleRenderer;
