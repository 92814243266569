"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SequenceDiagramRenderer = void 0;
const lib_1 = require("../lib");
const edge_1 = require("./edge");
const util_1 = require("./util");
// TODO make them configurable.
const hSpacing = 0;
const hPadding = 2;
const vSpacing = 1;
class SequenceDiagramRenderer {
    _generateLayoutObjects(edges) {
        /**
         Layout algorithm:
         1. Object ordering: the order they appeared in the sequences/edges.
         2. Width:
            let current width W = 0
            len(message) is defined as len(message string) + 7 (" - message -> ")
            start from the first edge and for each edge:
              - if origin is not added to the sequence diagram yet, add it to the
                end with S units away from the last object.
              - if destination is not added to the sequence, add it to the end with
                max(S, len(message) - W) units away from the last object.
              - if both origin and destination are already in the diagram but there
                is not sufficient space to put in message, move whatever is on the
                right and onwards.
              - if origin and destination are the same, push the objects on the
                right as needed
            this step will determine the horizontal positions of all object.
         3. Render:
            start from the first edge and for each edge, render in top down order
            and leave L units in-between.
         */
        // Didn't use a lookup table since there shouldn't be many objects.
        const objects = [];
        edges.forEach((edge) => {
            // Blank line and horizontal line doesn't affect width
            if (!edge.type || edge.type === '#') {
                return;
            }
            let existingOriginIndex = (0, util_1.findByName)(objects, edge.origin);
            if (existingOriginIndex < 0) {
                objects.push((0, util_1.createLayoutObject)(edge.origin, hSpacing, hPadding, objects.length > 0 ? objects[objects.length - 1] : null));
                existingOriginIndex = objects.length - 1;
            }
            const existingOrigin = objects[existingOriginIndex];
            let existingDestinationIndex = (0, util_1.findByName)(objects, edge.destination);
            if (existingDestinationIndex < 0) {
                objects.push((0, util_1.createLayoutObject)(edge.destination, hSpacing, hPadding, objects[objects.length - 1]));
                existingDestinationIndex = objects.length - 1;
            }
            const existingDestination = objects[existingDestinationIndex];
            const distance = Math.abs(existingOrigin.x +
                existingOrigin.leftMargin -
                (existingDestination.x + existingDestination.leftMargin));
            if (existingOriginIndex === existingDestinationIndex) {
                /**
                 * The number 3 comes from the following shape:
                 * ├──┐
                 * │  msg1234567
                 * │<─┘
                 */
                const widthNeeded = existingOrigin.leftMargin + (0, util_1.stringWidth)(edge.message) + 3;
                if (widthNeeded > existingOrigin.width) {
                    existingOrigin.rightExtra = widthNeeded - existingOrigin.width;
                    const len = objects.length;
                    if (existingOriginIndex < len - 1) {
                        const nextIndex = existingOriginIndex + 1;
                        const nextObject = objects[nextIndex];
                        const rightBoundary = existingOrigin.x +
                            existingOrigin.width +
                            existingOrigin.rightExtra;
                        if (rightBoundary >= nextObject.x + nextObject.leftMargin) {
                            const delta = rightBoundary - (nextObject.x + nextObject.leftMargin) + 1;
                            for (let i = nextIndex; i < len; ++i) {
                                objects[i].x += delta;
                            }
                        }
                    }
                }
            }
            else if (distance < (0, util_1.messageLength)(edge.message)) {
                const len = objects.length;
                const delta = (0, util_1.messageLength)(edge.message) - distance;
                for (let i = Math.max(existingOriginIndex, existingDestinationIndex); i < len; ++i) {
                    objects[i].x += delta;
                }
            }
        });
        return objects;
    }
    render(sequences) {
        const edges = (0, edge_1.generateEdges)(sequences);
        const objects = this._generateLayoutObjects(edges);
        const maxObjectHeight = Math.max(1, ...objects.map((object) => (0, util_1.stringHeight)(object.name)));
        // Object names are surrounded by rectangles
        let height = maxObjectHeight + 2;
        edges.forEach((edge) => {
            if (edge.type &&
                (edge.type === '#' || edge.origin === edge.destination)) {
                height += (0, util_1.stringHeight)(edge.message) + 2;
            }
            else {
                height += (0, util_1.stringHeight)(edge.message);
            }
            height += vSpacing;
        });
        // Allow lanes to extend a little
        height++;
        const board = Array(height);
        const lastObject = objects[objects.length - 1];
        for (let i = 0; i < height; ++i) {
            board[i] = Array(lastObject.x + lastObject.width + lastObject.rightExtra).fill(' ');
        }
        // Draw objects surrounded by rectangles in the first row
        objects.forEach((obj) => {
            if (obj.name) {
                const leftEdge = obj.x + hSpacing;
                let rightEdge = obj.x + obj.leftMargin * 2 - hSpacing - 1;
                if ((0, util_1.stringWidth)(obj.name) % 2 == 1) {
                    rightEdge++;
                }
                (0, lib_1.rectangle)(leftEdge, 0, rightEdge, (obj.leftMargin - hSpacing) * 2, maxObjectHeight + 1, board);
                (0, lib_1.internalCenteredPrint)(obj.name, leftEdge + hPadding, 1, (0, util_1.stringWidth)(obj.name), board);
                board[maxObjectHeight + 1][obj.x + obj.leftMargin] = '┬';
                // Vertical lanes
                for (let i = maxObjectHeight + 2; i < height; ++i) {
                    board[i][obj.x + obj.leftMargin] = '│';
                }
            }
        });
        let y = 2 + vSpacing + maxObjectHeight;
        edges.forEach((edge) => {
            // Empty type denotes blank line
            if (!edge.type) {
                y += vSpacing + 1;
                return;
            }
            // Horizontal line
            if (edge.type === '#') {
                const end = board[0].length;
                (0, lib_1.internalHorizontalLine)(0, y + 1, end, board);
                (0, lib_1.internalCenteredPrint)(edge.message, 0, y + 1, end, board, ' ', ' ');
                y += vSpacing + 3;
                return;
            }
            const originIndex = (0, util_1.findByName)(objects, edge.origin);
            const destinationIndex = (0, util_1.findByName)(objects, edge.destination);
            const origin = objects[originIndex];
            const destination = objects[destinationIndex];
            if (originIndex < destinationIndex) {
                const start = origin.x + origin.leftMargin + 1;
                const end = destination.x + destination.leftMargin - 1;
                let x = start;
                const height = (0, util_1.stringHeight)(edge.message);
                board[y + height - 1][x - 1] = origin.name ? '├' : edge.type;
                for (; x < end; ++x) {
                    board[y + height - 1][x] = edge.type;
                }
                board[y + height - 1][x] = '>';
                // "start + 1" because the start is just one character
                y += (0, lib_1.internalCenteredPrint)(edge.message, start + 1, y, end - start - 2, board, ' ', ' ');
                y--;
            }
            else if (originIndex > destinationIndex) {
                const start = origin.x + origin.leftMargin - 1;
                const end = destination.x + destination.leftMargin + 1;
                let x = start;
                const height = (0, util_1.stringHeight)(edge.message);
                board[y + height - 1][x + 1] = origin.name ? '┤' : edge.type;
                for (; x > end; --x) {
                    board[y + height - 1][x] = edge.type;
                }
                board[y + height - 1][x] = '<';
                // "end + 2" because the end is "<-"
                y += (0, lib_1.internalCenteredPrint)(edge.message, end + 2, y, start - end - 2, board, ' ', ' ');
                y--;
            }
            else {
                board[y][origin.x + origin.leftMargin] = destination.name ? '├' : ' ';
                (0, lib_1.internalCenteredPrint)(edge.type + edge.type + '┐', origin.x + origin.leftMargin + 1, y, 3, board);
                y++;
                y += (0, lib_1.internalCenteredPrint)(edge.message, origin.x + origin.leftMargin + 3, y, (0, util_1.stringWidth)(edge.message), board);
                (0, lib_1.internalCenteredPrint)('<' + edge.type + '┘', origin.x + origin.leftMargin + 1, y, 3, board);
            }
            y++;
            y += vSpacing;
        });
        // return '\'' + board.map(row => (row.join(''))).join('\\n\' +\n\'') + '\'';
        return board.map((row) => row.join('')).join('\n');
    }
}
exports.SequenceDiagramRenderer = SequenceDiagramRenderer;
