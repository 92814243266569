"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateEdges = void 0;
function _translateName(translateTable, key) {
    if (key in translateTable) {
        return translateTable[key].toString();
    }
    return key;
}
function _generateEdgesForOneSequence(sequence, translateTable) {
    /**
     sequence: obj --|.. msg -> obj? { --|.. msg -> obj? }
      messages: [{origin, message?, type?}]
    */
    const trimmed = sequence.trim();
    // Blank line
    if (!trimmed) {
        return [
            {
                message: '',
                type: '',
            },
        ];
    }
    // Horizontal line
    if (trimmed.startsWith('--') && trimmed.endsWith('--')) {
        return [
            {
                message: trimmed.substring(2, trimmed.length - 2).trim(),
                type: '#',
            },
        ];
    }
    const messages = sequence
        .split('->')
        .map((s) => s.trim())
        .map((originAndMessage) => {
        const dash = originAndMessage.split('--').map((s) => s.trim());
        const dot = originAndMessage.split('..').map((s) => s.trim());
        if (dash.length === 2) {
            return {
                origin: _translateName(translateTable, dash[0]),
                message: dash[1],
                type: '─',
            };
        }
        else if (dot.length === 2) {
            return {
                origin: _translateName(translateTable, dot[0]),
                message: dot[1],
                type: '∙',
            };
        }
        // It's the last object
        return {
            origin: _translateName(translateTable, originAndMessage.trim()),
            message: '',
            type: '',
        };
    });
    const edges = [];
    let i = 0;
    while (i + 1 < messages.length) {
        edges.push(Object.assign(Object.assign({}, messages[i]), { destination: messages[i + 1].origin }));
        i++;
    }
    return edges;
}
function generateEdges(sequences) {
    const edges = [];
    const translateTable = {};
    sequences.forEach((sequence) => {
        const trimmed = sequence.trim();
        if (trimmed.startsWith('##') && trimmed.endsWith('##')) {
            const directive = trimmed.substring(2, trimmed.length - 2).trim();
            const [key, value] = directive.split('=', 2);
            if (key && value && key.trim() && value.trim()) {
                translateTable[key.trim()] = value.trim();
            }
        }
        else {
            edges.push(..._generateEdgesForOneSequence(sequence, translateTable));
        }
    });
    return edges;
}
exports.generateEdges = generateEdges;
