import React, { Component } from 'react';
import Clipboard from 'react-clipboard.js';

class ASCIIPre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copiedToClipboard: false
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value !== prevProps.value) {
      this.setState({copiedToClipboard: false});
    }
  }
  render() {
    if (!this.props.value) {
      return <div></div>;
    }
    return (
      <div
        className="Box"
        style={{
          width: '80%',
          margin: 'auto'
        }}>
        <div className="Box-body" style={{overflow: 'scroll'}}>
          <pre
            style={
              {
                fontFamily: 'Menlo',
                lineHeight: '17px',
                fontSize: '14px'
              }
            }>
            {this.props.value}
          </pre>
        </div>
        <div className="Box-Footer">
          {
            this.props.value &&
            <Clipboard
              data-clipboard-text={this.props.value}
              onSuccess={() => {
                this.setState({copiedToClipboard: true});
              }}
              >
              Copy the ASCII drawing to clipboard
            </Clipboard>
          }
          {
            this.state.copiedToClipboard &&
            <span style={{color: 'green'}}> ✓</span>
          }
        </div>
      </div>
    );
  }
}

export default ASCIIPre;
