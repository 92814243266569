import React, { Component } from 'react';
import ToolContainer from '../../common/tool-container';

const table = {
    "0101": "0102",
    "0102": "0103",
    "0103": "0104",
    "0104": "0100",
    "0110": "0120",
    "0111": "0121",
    "0112": "0122",
    "0113": "0123",
    "0114": "0110",
    "0120": "0130",
    "0121": "0131",
    "0122": "0132",
    "0123": "0133",
    "0124": "0120",
    "0130": "0140",
    "0131": "0132",
    "0132": "0133",
    "0133": "0143",
    "0134": "0130",
    "0140": "0100",
    "0141": "0101",
    "0142": "0102",
    "0143": "0103",
    "0144": "0104",
    "0201": "1101",
    "0202": "1102",
    "0203": "0200",
    "0204": "0200",
    "0210": "1110",
    "0211": "1111",
    "0212": "1112",
    "0213": "0210",
    "0214": "0210",
    "0220": "1120",
    "0221": "1121",
    "0222": "1122",
    "0223": "0220",
    "0224": "0220",
    "0230": "0200",
    "0231": "0201",
    "0232": "0202",
    "0233": "1133",
    "0234": "1134",
    "0240": "0200",
    "0241": "0201",
    "0242": "0202",
    "0243": "1143",
    "0244": "1144",
    "0301": "1201",
    "0302": "0300",
    "0303": "0300",
    "0304": "0300",
    "0310": "1210",
    "0311": "1211",
    "0312": "0310",
    "0313": "0310",
    "0314": "0310",
    "0320": "0300",
    "0321": "0301",
    "0322": "1222",
    "0323": "1223",
    "0324": "1224",
    "0330": "0300",
    "0331": "0301",
    "0332": "1232",
    "0333": "0303",
    "0334": "1234",
    "0340": "0300",
    "0341": "0301",
    "0342": "1242",
    "0343": "1243",
    "0344": "1244",
    "0401": "0400",
    "0402": "0400",
    "0403": "0400",
    "0404": "0400",
    "0410": "0400",
    "0411": "1311",
    "0412": "1312",
    "0413": "1313",
    "0414": "1314",
    "0420": "0400",
    "0421": "1321",
    "0422": "1322",
    "0423": "1323",
    "0424": "1324",
    "0430": "0400",
    "0431": "1331",
    "0432": "1332",
    "0433": "1333",
    "0434": "1334",
    "0440": "0400",
    "0441": "1341",
    "0442": "1342",
    "0443": "1343",
    "0444": "1344",
    "1001": "1002",
    "1002": "1003",
    "1003": "1004",
    "1004": "1000",
    "1010": "1020",
    "1011": "1021",
    "1012": "1022",
    "1013": "1023",
    "1014": "1010",
    "1020": "1030",
    "1021": "1031",
    "1022": "1032",
    "1023": "1033",
    "1024": "1020",
    "1030": "1040",
    "1031": "1032",
    "1032": "1033",
    "1033": "1043",
    "1034": "1030",
    "1040": "1000",
    "1041": "1001",
    "1042": "1002",
    "1043": "1003",
    "1044": "1004",
    "1101": "1102",
    "1102": "1103",
    "1103": "1104",
    "1104": "1100",
    "1110": "1120",
    "1111": "1121",
    "1112": "1122",
    "1113": "1123",
    "1114": "1124",
    "1120": "1130",
    "1121": "1131",
    "1122": "1132",
    "1123": "1133",
    "1124": "1134",
    "1130": "1140",
    "1131": "1141",
    "1132": "1142",
    "1133": "1143",
    "1134": "1130",
    "1140": "1100",
    "1141": "1101",
    "1142": "1102",
    "1143": "1103",
    "1144": "1104",
    "1201": "1202",
    "1202": "1203",
    "1203": "1200",
    "1204": "1200",
    "1210": "1220",
    "1211": "1221",
    "1212": "1222",
    "1213": "1223",
    "1214": "1224",
    "1220": "1240",
    "1221": "1231",
    "1222": "1232",
    "1223": "1224",
    "1224": "1234",
    "1230": "1200",
    "1231": "1241",
    "1232": "1242",
    "1233": "1243",
    "1234": "1204",
    "1240": "1200",
    "1241": "1201",
    "1242": "1202",
    "1243": "1203",
    "1244": "1204",
    "1301": "1302",
    "1302": "1300",
    "1303": "1300",
    "1304": "1300",
    "1310": "1320",
    "1311": "1321",
    "1312": "1322",
    "1313": "1323",
    "1314": "1324",
    "1320": "1300",
    "1321": "1331",
    "1322": "1332",
    "1323": "1333",
    "1324": "1334",
    "1330": "1300",
    "1331": "1341",
    "1332": "1342",
    "1333": "1343",
    "1334": "1304",
    "1340": "1300",
    "1341": "1301",
    "1342": "1302",
    "1343": "1303",
    "1344": "1304",
    "1401": "1400",
    "1402": "1400",
    "1403": "1400",
    "1404": "1400",
    "1410": "1400",
    "1411": "1421",
    "1412": "1422",
    "1413": "1423",
    "1414": "1424",
    "1420": "1400",
    "1421": "1431",
    "1422": "1432",
    "1423": "1424",
    "1424": "1434",
    "1430": "1400",
    "1431": "1441",
    "1432": "1442",
    "1433": "1443",
    "1434": "1404",
    "1440": "1400",
    "1441": "1401",
    "1442": "1402",
    "1443": "1444",
    "1444": "1404",
    "2001": "1101",
    "2002": "1102",
    "2003": "2000",
    "2004": "2000",
    "2010": "1110",
    "2011": "1111",
    "2012": "1112",
    "2013": "2010",
    "2014": "2010",
    "2020": "1120",
    "2021": "1121",
    "2022": "1122",
    "2023": "2020",
    "2024": "2020",
    "2030": "2000",
    "2031": "2001",
    "2032": "2002",
    "2033": "1133",
    "2034": "1134",
    "2040": "2000",
    "2041": "2001",
    "2042": "2002",
    "2043": "1143",
    "2044": "2004",
    "2101": "2102",
    "2102": "2104",
    "2103": "2100",
    "2104": "2100",
    "2110": "2120",
    "2111": "2131",
    "2112": "2132",
    "2113": "2110",
    "2114": "2134",
    "2120": "2140",
    "2121": "2141",
    "2122": "2142",
    "2123": "2143",
    "2124": "2120",
    "2130": "2100",
    "2131": "2101",
    "2132": "2102",
    "2133": "2130",
    "2134": "2104",
    "2140": "2100",
    "2141": "2101",
    "2142": "2102",
    "2143": "2140",
    "2144": "2104",
    "2201": "2203",
    "2202": "2204",
    "2203": "2200",
    "2204": "2200",
    "2210": "2230",
    "2211": "2231",
    "2212": "2232",
    "2213": "2233",
    "2214": "2234",
    "2220": "2240",
    "2221": "2241",
    "2222": "2242",
    "2223": "2243",
    "2224": "2244",
    "2230": "2200",
    "2231": "2201",
    "2232": "2202",
    "2233": "2203",
    "2234": "2204",
    "2240": "2200",
    "2241": "2201",
    "2242": "2202",
    "2243": "2203",
    "2244": "2204",
    "2301": "2303",
    "2302": "2300",
    "2303": "2300",
    "2304": "2300",
    "2310": "2330",
    "2311": "2331",
    "2312": "2332",
    "2313": "2310",
    "2314": "2334",
    "2320": "2300",
    "2321": "2341",
    "2322": "2342",
    "2323": "2343",
    "2324": "2344",
    "2330": "2300",
    "2331": "2301",
    "2332": "2302",
    "2333": "2303",
    "2334": "2304",
    "2340": "2300",
    "2341": "2301",
    "2342": "2302",
    "2343": "2303",
    "2344": "2304",
    "2401": "2400",
    "2402": "2400",
    "2403": "2400",
    "2404": "2400",
    "2410": "2400",
    "2411": "2431",
    "2412": "2432",
    "2413": "2433",
    "2414": "2434",
    "2420": "2400",
    "2421": "2441",
    "2422": "2442",
    "2423": "2443",
    "2424": "2444",
    "2430": "2400",
    "2431": "2401",
    "2432": "2402",
    "2433": "2403",
    "2434": "2404",
    "2440": "2400",
    "2441": "2401",
    "2442": "2402",
    "2443": "2403",
    "2444": "2404",
    "3001": "1201",
    "3002": "3000",
    "3003": "3000",
    "3004": "3000",
    "3010": "1210",
    "3011": "1211",
    "3012": "3010",
    "3013": "3010",
    "3014": "3010",
    "3020": "3000",
    "3021": "3001",
    "3022": "1222",
    "3023": "1223",
    "3024": "1224",
    "3030": "3000",
    "3031": "3001",
    "3032": "1232",
    "3033": "2133",
    "3034": "1234",
    "3040": "3000",
    "3041": "3001",
    "3042": "1242",
    "3043": "1243",
    "3044": "2144",
    "3101": "3104",
    "3102": "3100",
    "3103": "3100",
    "3104": "3100",
    "3110": "3140",
    "3111": "3141",
    "3112": "3142",
    "3113": "3143",
    "3114": "3110",
    "3120": "3100",
    "3121": "3101",
    "3122": "3102",
    "3123": "3120",
    "3124": "3104",
    "3130": "3100",
    "3131": "3101",
    "3132": "3102",
    "3133": "3130",
    "3134": "3104",
    "3140": "3100",
    "3141": "3101",
    "3142": "3102",
    "3143": "3140",
    "3144": "3104",
    "3201": "3204",
    "3202": "3200",
    "3203": "3200",
    "3204": "3200",
    "3210": "3240",
    "3211": "3241",
    "3212": "3242",
    "3213": "3243",
    "3214": "3244",
    "3220": "3200",
    "3221": "3201",
    "3222": "3202",
    "3223": "3203",
    "3224": "3204",
    "3230": "3200",
    "3231": "3201",
    "3232": "3202",
    "3233": "3203",
    "3234": "3204",
    "3240": "3200",
    "3241": "3201",
    "3242": "3202",
    "3243": "3203",
    "3244": "3204",
    "3301": "3304",
    "3302": "3300",
    "3303": "3300",
    "3304": "3300",
    "3310": "3340",
    "3311": "3341",
    "3312": "3310",
    "3313": "3343",
    "3314": "3310",
    "3320": "3300",
    "3321": "3301",
    "3322": "3302",
    "3323": "3303",
    "3324": "3304",
    "3330": "3300",
    "3331": "3301",
    "3332": "3302",
    "3333": "3303",
    "3334": "3304",
    "3340": "3300",
    "3341": "3301",
    "3342": "3302",
    "3343": "3303",
    "3344": "3304",
    "3401": "3400",
    "3402": "3400",
    "3403": "3400",
    "3404": "3400",
    "3410": "3400",
    "3411": "3441",
    "3412": "3442",
    "3413": "3443",
    "3414": "3444",
    "3420": "3400",
    "3421": "3401",
    "3422": "3402",
    "3423": "3403",
    "3424": "3404",
    "3430": "3400",
    "3431": "3401",
    "3432": "3402",
    "3433": "3403",
    "3434": "3404",
    "3440": "3400",
    "3441": "3401",
    "3442": "3402",
    "3443": "3403",
    "3444": "3404",
    "4001": "4000",
    "4002": "4000",
    "4003": "4000",
    "4004": "4000",
    "4010": "4000",
    "4011": "1311",
    "4012": "1312",
    "4013": "1313",
    "4014": "1314",
    "4020": "4000",
    "4021": "1321",
    "4022": "1322",
    "4023": "1323",
    "4024": "1324",
    "4030": "4000",
    "4031": "1331",
    "4032": "1332",
    "4033": "1333",
    "4034": "1334",
    "4040": "4000",
    "4041": "1341",
    "4042": "1342",
    "4043": "1343",
    "4044": "2244",
    "4101": "4100",
    "4102": "4100",
    "4103": "4100",
    "4104": "4100",
    "4110": "4100",
    "4111": "4101",
    "4112": "4102",
    "4113": "4110",
    "4114": "4104",
    "4120": "4100",
    "4121": "4101",
    "4122": "4102",
    "4123": "4120",
    "4124": "4104",
    "4130": "4100",
    "4131": "4101",
    "4132": "4102",
    "4133": "4130",
    "4134": "4104",
    "4140": "4100",
    "4141": "4101",
    "4142": "4102",
    "4143": "4140",
    "4144": "4104",
    "4201": "4200",
    "4202": "4200",
    "4203": "4200",
    "4204": "4200",
    "4210": "4200",
    "4211": "4201",
    "4212": "4202",
    "4213": "4203",
    "4214": "4204",
    "4220": "4200",
    "4221": "4201",
    "4222": "4202",
    "4223": "4203",
    "4224": "4204",
    "4230": "4200",
    "4231": "4201",
    "4232": "4202",
    "4233": "4203",
    "4234": "4204",
    "4240": "4200",
    "4241": "4201",
    "4242": "4202",
    "4243": "4203",
    "4244": "4204",
    "4301": "4300",
    "4302": "4300",
    "4303": "4300",
    "4304": "4300",
    "4310": "4300",
    "4311": "4301",
    "4312": "4302",
    "4313": "4303",
    "4314": "4304",
    "4320": "4300",
    "4321": "4301",
    "4322": "4302",
    "4323": "4303",
    "4324": "4304",
    "4330": "4300",
    "4331": "4301",
    "4332": "4302",
    "4333": "4303",
    "4334": "4304",
    "4340": "4300",
    "4341": "4301",
    "4342": "4302",
    "4343": "4303",
    "4344": "4304",
    "4401": "4400",
    "4402": "4400",
    "4403": "4400",
    "4404": "4400",
    "4410": "4400",
    "4411": "4401",
    "4412": "4402",
    "4413": "4410",
    "4414": "4410",
    "4420": "4400",
    "4421": "4401",
    "4422": "4402",
    "4423": "4403",
    "4424": "4404",
    "4430": "4400",
    "4431": "4401",
    "4432": "4402",
    "4433": "4403",
    "4434": "4404",
    "4440": "4400",
    "4441": "4401",
    "4442": "4402",
    "4443": "4403",
    "4444": "4404"
  };


class Stick extends Component {
  render() {
    return (
      <ToolContainer
        key={'Stick'}
        t={'stick'}
        multiline={false}
        inputPrompt="<myLeft><myRight><theirLeft><theirRight>"
        help={['0 to 4']}
        sampleInput={'1111'}
        errorMessage={'Invalid input, format: <myLeft><myRight><theirLeft><theirRight>'}
        onSubmit={input => table[input]}
      />
    );
  }
}

export default Stick;
