"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScheduleRenderer = void 0;
const lib_1 = require("./lib");
const moment = require('moment');
function notEmpty(value) {
    return value !== null;
}
class ScheduleRenderer {
    constructor() {
        /**
         * Render a list of schedules
         * @param schedules ['<startDate:YYYY-MM-DD><space><endDate:YYYY-MM-DD><space><name>'}]
         * @param type: calendar|weekly|daily
         */
        this.render = (schedules, type) => {
            const scheduleObjects = schedules
                .map((schedule) => {
                const segments = schedule.split(' ');
                if (segments.length < 3) {
                    return null;
                }
                const startDateStr = segments[0];
                const endDateStr = segments[1];
                const name = segments.slice(2).join(' ');
                const startDate = moment(startDateStr).toDate();
                const endDate = moment(endDateStr).toDate();
                return { name, startDate, endDate, slotIndex: 0 };
            })
                .filter(notEmpty);
            const epoch = moment([1970, 1, 1]);
            scheduleObjects.sort((a, b) => {
                return a.endDate.getTime() - b.endDate.getTime();
            });
            const lastSchedule = scheduleObjects[scheduleObjects.length - 1];
            const lastDays = moment(lastSchedule.endDate).diff(epoch, 'days');
            scheduleObjects.sort((a, b) => {
                return a.startDate.getTime() - b.startDate.getTime();
            });
            const firstSchedule = scheduleObjects[0];
            const firstDays = moment(firstSchedule.startDate).diff(epoch, 'days');
            const totalDays = lastDays - firstDays + 1;
            /**
             * span: {slots, scheduleObjects}
             */
            const span = Array(totalDays);
            for (let i = 0; i < totalDays; ++i) {
                span[i] = {
                    slots: Array(scheduleObjects.length).fill(false),
                    scheduleObjects: [],
                };
            }
            scheduleObjects.forEach((scheduleObject) => {
                const startIndex = moment(scheduleObject.startDate).diff(epoch, 'days') - firstDays;
                const slots = span[startIndex].slots;
                let firstAvailableIndex = -1;
                for (let i = 0; i < slots.length; ++i) {
                    if (!slots[i]) {
                        firstAvailableIndex = i;
                        break;
                    }
                }
                scheduleObject.slotIndex = firstAvailableIndex;
                slots[scheduleObject.slotIndex] = true;
                const endIndex = moment(scheduleObject.endDate).diff(epoch, 'days') - firstDays;
                for (let i = startIndex; i <= endIndex; ++i) {
                    const copiedScheduleObject = Object.assign({}, scheduleObject);
                    if (i === startIndex) {
                        copiedScheduleObject.isStart = true;
                    }
                    if (i === endIndex) {
                        copiedScheduleObject.isEnd = true;
                    }
                    span[i].scheduleObjects.push(copiedScheduleObject);
                    span[i].slots[scheduleObject.slotIndex] = true;
                }
            });
            let maxSlots = 0;
            span.forEach((s) => {
                let maxSlotsInSpan = 0;
                for (let i = s.slots.length - 1; i >= 0; --i) {
                    if (s.slots[i]) {
                        maxSlotsInSpan = i + 1;
                        break;
                    }
                }
                maxSlots = Math.max(maxSlots, maxSlotsInSpan);
            });
            // if (type === 'calendar') {
            //   return _renderCalendar(span);
            // }
            // return _renderDaily(span, maxSlots);
            return this._renderCalendar(span);
        };
        this._renderCalendar = (span) => {
            const firstSchedule = span[0].scheduleObjects[0];
            const lastSchedule = span[span.length - 1].scheduleObjects[span[span.length - 1].scheduleObjects.length - 1];
            const headGap = moment(firstSchedule.startDate).day();
            const tailGap = 6 - moment(lastSchedule.endDate).day();
            const emptySpanUnit = { scheduleObjects: [] };
            const normalizedSpan = Array(headGap)
                .fill(emptySpanUnit)
                .concat(span)
                .concat(Array(tailGap).fill(emptySpanUnit));
            const totalWeeks = normalizedSpan.length / 7;
            let startDay = moment(firstSchedule.startDate).add(-headGap, 'days');
            let maxWidth = Array(7).fill(5);
            for (let i = 0; i < normalizedSpan.length; ++i) {
                let maxWidthPerDay = 0;
                for (let j = 0; j < normalizedSpan[i].scheduleObjects.length; ++j) {
                    if (normalizedSpan[i].scheduleObjects[j].isStart ||
                        normalizedSpan[i].scheduleObjects[j].isEnd) {
                        maxWidthPerDay = Math.max(maxWidthPerDay, normalizedSpan[i].scheduleObjects[j].name.length + 2);
                    }
                }
                maxWidth[i % 7] = Math.max(maxWidth[i % 7], maxWidthPerDay);
            }
            let totalWidth = 0;
            for (let i = 0; i < 7; ++i) {
                totalWidth += maxWidth[i];
            }
            // Array of strings
            const board = [];
            const weekdayLine = [];
            const weekdayLineDay = moment(startDay);
            const weekdayLineDayStr = weekdayLineDay.format('MMM YYYY');
            const dateStrGap = (0, lib_1.stringOfChar)(' ', weekdayLineDayStr.length + 1);
            for (let i = 0; i < 7; ++i) {
                weekdayLine.push((0, lib_1.placeString)(weekdayLineDay.format('ddd'), (0, lib_1.stringOfChar)(' ', maxWidth[i]), 'left'));
                weekdayLineDay.add(1, 'days');
            }
            board.push(dateStrGap + weekdayLine.join('') + dateStrGap);
            board.push(dateStrGap + (0, lib_1.stringOfChar)('━', totalWidth) + dateStrGap);
            let lastDateStr = '';
            for (let i = 0; i < totalWeeks; ++i) {
                let maxSlotPerWeek = 0;
                let totalSchedulesPerWeek = 0;
                for (let j = 0; j < 7; ++j) {
                    const s = normalizedSpan[i * 7 + j];
                    s.scheduleObjects.forEach((scheduleObject) => {
                        totalSchedulesPerWeek++;
                        maxSlotPerWeek = Math.max(maxSlotPerWeek, scheduleObject.slotIndex + 1);
                    });
                }
                if (totalSchedulesPerWeek === 0) {
                    startDay.add(7, 'days');
                    continue;
                }
                const currentWeekDay = moment(startDay);
                let dateStr = currentWeekDay.format('MMM YYYY');
                let line = [
                    (dateStr !== lastDateStr
                        ? dateStr
                        : (0, lib_1.stringOfChar)(' ', dateStr.length)) + ' ',
                ];
                line.push((0, lib_1.stringOfChar)(' ', totalWidth));
                lastDateStr = dateStr;
                currentWeekDay.add(6, 'days');
                dateStr = currentWeekDay.format('MMM YYYY');
                line.push(' ' +
                    (dateStr !== lastDateStr
                        ? dateStr
                        : (0, lib_1.stringOfChar)(' ', dateStr.length)));
                lastDateStr = dateStr;
                board.push(line.join(''));
                line = [];
                const currentDay = moment(startDay);
                for (let j = 0; j < 7; ++j) {
                    line.push((0, lib_1.placeString)(currentDay.format('DD'), (0, lib_1.stringOfChar)(' ', maxWidth[j]), 'left'));
                    currentDay.add(1, 'days');
                }
                board.push(dateStrGap + line.join('') + dateStrGap);
                startDay.add(7, 'days');
                for (let k = 0; k < maxSlotPerWeek; ++k) {
                    const line = [];
                    let goneThroughSchedule = null;
                    let foundGoneThrough = true;
                    // Speical handling: whole-week-in-the-middle schedule
                    for (let j = 0; j < 7; ++j) {
                        const s = normalizedSpan[i * 7 + j];
                        s.scheduleObjects.forEach((scheduleObject) => {
                            if (scheduleObject.slotIndex !== k) {
                                return;
                            }
                            goneThroughSchedule = scheduleObject;
                            if (scheduleObject.isStart || scheduleObject.isEnd) {
                                foundGoneThrough = false;
                            }
                        });
                    }
                    if (foundGoneThrough && goneThroughSchedule) {
                        const schedule = goneThroughSchedule;
                        board.push(dateStrGap +
                            (0, lib_1.placeString)(schedule.name, (0, lib_1.stringOfChar)('═', totalWidth)) +
                            dateStrGap);
                    }
                    else {
                        for (let j = 0; j < 7; ++j) {
                            const s = normalizedSpan[i * 7 + j];
                            let found = false;
                            s.scheduleObjects.forEach((scheduleObject) => {
                                if (scheduleObject.slotIndex !== k) {
                                    return;
                                }
                                found = true;
                                let name = scheduleObject.name;
                                if (!scheduleObject.isStart && !scheduleObject.isEnd) {
                                    name = (0, lib_1.stringOfChar)('═', maxWidth[j]);
                                }
                                else {
                                    name =
                                        (scheduleObject.isStart ? '[' : '') +
                                            name +
                                            (scheduleObject.isEnd ? ']' : '');
                                    name = (0, lib_1.placeString)(name, (0, lib_1.stringOfChar)(scheduleObject.isEnd ? ' ' : '═', maxWidth[j]), 'left');
                                }
                                line.push(name);
                            });
                            if (!found) {
                                line.push((0, lib_1.stringOfChar)(' ', maxWidth[j]));
                            }
                        }
                        board.push(dateStrGap + line.join('') + dateStrGap);
                    }
                }
            }
            // return '\'' + board.join('\\n\' +\n\'') + '\'';
            return board.join('\n');
        };
        // const _renderDaily = (span, maxSlots) => {
        //   const board = Array(maxSlots);
        //   for (let i = 0; i < maxSlots; ++i) {
        //     // TODO %-10s
        //     board[i] = stringOfChar('     ', span.length);
        //   }
        //   for (let i = 0; i < span.length; ++i) {
        //     const s = span[i];
        //     s.scheduleObjects.forEach(scheduleObject => {
        //       board[scheduleObject.slotIndex][i] = ' ' + scheduleObject.name + ' ';
        //     });
        //   }
        //   return board.map(row => row.join('')).join('\n');
        // };
    }
}
exports.ScheduleRenderer = ScheduleRenderer;
// const renderer = new ScheduleRenderer();
// const rendered = renderer.render([
//   '2011-01-01 2011-01-01',
//   '2011-01-01 2011-01-01 ddd',
//   '2011-01-02 2011-02-01 def',
//   '2011-01-03 2011-01-05 123',
//   '2011-01-06 2011-01-11 456',
//   '2011-01-01 2011-01-10 7333389',
//   '2011-01-11 2011-01-20 1233',
//   '2012-01-11 2012-01-20 1233',
// ], 'calendar');
// console.log(rendered);
