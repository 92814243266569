import React, { Component } from 'react';
import { expression } from 'ascii-eng';
import ToolContainer from '../../common/tool-container';

class ExpRenderer extends Component {
  render() {
    return (
      <ToolContainer
        key={'ExpRenderer'}
        t={'exp'}
        multiline={false}
        inputPrompt="Please input expression here"
        help={[]}
        sampleInput={'-(3-4)/5+1+((1+2/3^someFunction(4,5^((1/2)-3)))/5^3+7/(8/9/var/a))^2'}
        errorMessage="Incomplete expression"
        onSubmit={input => {
          const { parser, renderer } = expression;
          const parsed = new parser().parse(input);
          return (new renderer()).render(parsed);
        }}
      />
    );
  }
}

export default ExpRenderer;
